import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tooltip-doc',
  template: `
    <a class="ic-tooltip" [ngClass]="{'ic-tolt-input' : tipoCard === false}">
      <span>{{descripcion}}</span>
      <i class="material-icons">help</i>
    </a>
  `,
  styleUrls: ['./tooltips.scss']
})

export class TooltipDocsComponent implements OnInit {

  @Input() descripcion: string;
  @Input() tipoCard: boolean;

  constructor() { }

  ngOnInit() {
    if ( this.tipoCard === undefined || this.tipoCard == null ) {
      this.tipoCard = false;
    }
  }

}
