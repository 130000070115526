import { Injectable } from '@angular/core';
import {MatDialog} from '@angular/material';
import { Router } from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import { MessageService } from '../components/messages/message.service';
import { DBService } from './db.services';

@Injectable({
  providedIn: 'root',
})
export class ExpirationTimeService {

  private timeOutTokenWar;
  private timeOutTokenExp;

  constructor(private userIdle: UserIdleService, private messageService: MessageService, private _dbService : DBService, private _dialogRef: MatDialog,private _router: Router) {
    clearTimeout(this.timeOutTokenWar);
    clearTimeout(this.timeOutTokenExp);
  }

  setExpiracionSesion(timeSesionExp: number, mensaje: string){
    this.userIdle.setConfigValues({
      idle: this.convertMinutesToSeco(timeSesionExp)
    });

    this.userIdle.startWatching();

    this.userIdle.onTimerStart().subscribe(resp => {
      if(resp){
        console.log("Sesion cerrada por inactiva");
        this.cerrarSesion();
        this.messageService.showErrorHTML(mensaje);
      }
    });
  }

  setExpiracionTokenWarning(timeToken: number, mensaje: string){
    let hoy = new Date();
    console.log("Tiempo mensaje Warning empezado.", "Minutos: "+ timeToken, ", Milisegundos: "+ this.convertMinutesToMili(timeToken), hoy.getHours() + ':' + hoy.getMinutes() + ':' + hoy.getSeconds());
    var that = this;
    clearTimeout(this.timeOutTokenWar);
    this.timeOutTokenWar = setTimeout(function () {
      let hoy2 = new Date();
      console.log("Tiempo de mensaje Warning terminado", that.convertMinutesToMili(timeToken), hoy2.getHours() + ':' + hoy2.getMinutes() + ':' + hoy2.getSeconds());
      that.messageService.showWarning(mensaje);
    }, that.convertMinutesToMili(timeToken)
    );
  }

  setExpiracionToken(timeToken: number){
    let hoy = new Date();
    console.log("Tiempo expiracion token empezado.", "Minutos: "+ timeToken, ", Milisegundos: "+ this.convertMinutesToMili(timeToken), hoy.getHours() + ':' + hoy.getMinutes() + ':' + hoy.getSeconds());
    var that = this;
    clearTimeout(this.timeOutTokenExp);
    this.timeOutTokenExp = setTimeout(function () {
      let hoy2 = new Date();
      console.log("Tiempo de expiracion token terminado", that.convertMinutesToMili(timeToken),hoy2.getHours() + ':' + hoy2.getMinutes() + ':' + hoy2.getSeconds());
      that.cerrarSesion();
    }, that.convertMinutesToMili(timeToken)
    );
  }

  cerrarSesion(){
    localStorage.clear();
    Swal.close();
    this._dialogRef.closeAll();
    this._dbService.setRespresentante(null);
    clearTimeout(this.timeOutTokenWar);
    clearTimeout(this.timeOutTokenExp);
    this.userIdle.stopWatching();
    this._router.navigate(['/login']);
  }

  convertMinutesToMili(minutes: number): number{
    return (minutes * 60)*1000;
  }

  convertMinutesToSeco(minutes: number): number{
    return minutes * 60;
  }
}
