// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  host: 'https://mv90douveg.execute-api.us-east-1.amazonaws.com',
  host2: 'https://mv90douveg.execute-api.us-east-1.amazonaws.com',
  captchaSiteKey: "6Le7XCwlAAAAAO4jA31wpUMj933UXgpsLtEOb9z1", //captcha test
};

interface PathUri {
  port: string;
  context: string;
  service: string;
}

// const apiGetway = '/qas/';
const apiGetway = '/stg/';

// General
const url: PathUri = { port: apiGetway, context: 'renovacion/', service: 'pago/url' };
const terminos: PathUri = { port: apiGetway, context: 'renovacion/', service: 'terminosYCondiciones' };
const tipoIdentificacion: PathUri = { port: apiGetway, context: 'renovacion/', service: 'tiposIdentificacion/obtenerTiposIdentificacion' };
const tipoIdentificacionLogin: PathUri = { port: apiGetway, context: 'renovacion/', service: 'tiposIdentificacion/obtenerTiposIdentificacionLogin' };
const sedesAdministrativas: PathUri = { port: apiGetway, context: 'renovacion/', service: 'tiposSedeAdminsitrativa' };
const tiposReferencia: PathUri = { port: apiGetway, context: 'renovacion/', service: 'tiposReferencia' };
const gruposNIIF: PathUri = { port: apiGetway, context: 'renovacion/', service: 'getGruposNIIF' };
const tipoExportadorImportador: PathUri = { port: apiGetway, context: 'renovacion/', service: 'getTipoExportadorImportador' };
const TipoAportante: PathUri = { port: apiGetway, context: 'renovacion/', service: 'getTipoAportante' };
const EntesControl: PathUri = { port: apiGetway, context: 'renovacion/', service: 'getEntesControl' };
const CIIU: PathUri = { port: apiGetway, context: 'renovacion/', service: 'codigosCIIU' };
const esal: PathUri = { port: apiGetway, context: 'renovacion/', service: 'esal' };
const ley1780: PathUri = { port: apiGetway, context: 'renovacion/', service: 'ley1780' };
const paises: PathUri = { port: apiGetway, context: 'renovacion/', service: 'paises' };
const departamentos: PathUri = { port: apiGetway, context: 'renovacion/', service: 'departamentos' };
const municipios: PathUri = { port: apiGetway, context: 'renovacion/', service: 'municipios' };
const zonas: PathUri = { port: apiGetway, context: 'renovacion/', service: 'zonas' };
const ubicaciones: PathUri = { port: apiGetway, context: 'renovacion/', service: 'tiposDeUbicacion' };
const direcciones: PathUri = { port: apiGetway, context: 'renovacion/', service: 'formularios/' };
const direccionesPut: PathUri = { port: apiGetway, context: 'renovacion/', service: 'direcciones' };
const texto: PathUri = { port: apiGetway, context: 'renovacion/', service:'getTexto'};
const parametro: PathUri = { port: apiGetway, context: 'renovacion/', service:'parametro'};

// Formulario
const loginFormulario: PathUri = { port: apiGetway, context: 'renovacion/', service: 'login' };
const auditoria: PathUri = { port: apiGetway, context: 'renovacion/', service: 'auditoria/auditoriaAceptaciones' };
const tokenTime: PathUri = { port: apiGetway, context: 'renovacion/', service: 'token/tiempos' };
const matriculas: PathUri = { port: apiGetway, context: 'renovacion/', service: 'matriculas' };

//rues
const infoFinan: PathUri = { port: apiGetway, context: 'renovacion/', service: 'formularios' };
const datosOrganizacion: PathUri = { port: apiGetway, context: 'renovacion/', service: 'formularios' };
const actividadEconomica: PathUri = { port: apiGetway, context: 'renovacion/', service: 'formularios' };
const informacionComercial: PathUri = { port: apiGetway, context: 'renovacion/', service: 'formularios' };

// CUF
const cuf: PathUri = { port: apiGetway, context: 'renovacion/', service: 'cuf' };

// NOTIFIACIONES
const notificaciones: PathUri = { port: apiGetway, context: 'renovacion/', service: 'notificacion' };

//firma 
const firmas: PathUri = { port: apiGetway, context: 'renovacion/', service: 'firma' };
//recaudos
const pagos: PathUri = { port: apiGetway, context: 'renovacion/', service: 'pago' };
// Exports
export const getUrl: string = generateUri(url);
export const getTerminos: string = generateUri(terminos);
export const getTipoIdentificacion: string = generateUri(tipoIdentificacion);
export const getTipoIdentificacionLogin: string = generateUri(tipoIdentificacionLogin);
export const getSedesAdministrativas: string = generateUri(sedesAdministrativas);
export const getTiposReferencias: string = generateUri(tiposReferencia);
export const getGruposNIIF: string = generateUri(gruposNIIF);
export const getTipoExportadorImportador: string = generateUri(tipoExportadorImportador);
export const getTipoAportante: string = generateUri(TipoAportante);
export const getEntesControl: string = generateUri(EntesControl);
export const getPaises: string = generateUri(paises);
export const getDepartamentos: string = generateUri(departamentos);
export const getMunicipios: string = generateUri(municipios);
export const getZonas: string = generateUri(zonas);
export const getUbicacciones: string = generateUri(ubicaciones);
export const getDirecciones: string = generateUri(direcciones);
export const putDirecciones: string = generateUri(direccionesPut);
export const getTexto: String = generateUri(texto);
export const getCiiu: string = generateUri(CIIU);
export const getEsal: string = generateUri(esal);
export const allLey1780: string = generateUri(ley1780);
export const getLoginFormulario: string = generateUri(loginFormulario);
export const getAuditoria: string = generateUri(auditoria);
export const getTokenTime: string = generateUri(tokenTime);
export const getMatriculas: string = generateUri(matriculas);
export const getCuf: string = generateUri(cuf);
export const getNotificaciones: string = generateUri(notificaciones);
export const getInfoFinan: string = generateUri(infoFinan);
export const formOrganizacion: string = generateUri(datosOrganizacion);
export const getActividadEconomica: string = generateUri(actividadEconomica);
export const getInformacionComercial: string = generateUri(informacionComercial);
export const getParametro : string = generateUri(parametro);
export const firma: string = generateUri(firmas);
export const pago: string = generateUri(pagos);

function generateUri(path: PathUri): string {
  return path.port + path.context + path.service;
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
