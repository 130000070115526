import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

// Activa el estado de espera - loader
export class LoaderService {

    private _loader = new BehaviorSubject<boolean>(null);
    loader = this._loader.asObservable();

    public setLoader(state: boolean) {
      this._loader.next(state);
    }


}
