import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class MessageService {

  public showErrorNotify(message: string) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 3000
    });
    Toast.fire({
      type: 'error',
      title: message
    });
  }

  public showError(message: string) {
    Swal.fire({
      type: 'error',
      title: '<b>ERROR</b>',
      html: '<b>Se ha presentado el siguiente error:</b>' +
        '<p style="margin: 0px;font-size: 16px;">' + message + '</p>',
      confirmButtonText: 'Aceptar',
    });
  }

  public showErrorHTML(html: string){
    Swal.fire({
      type: 'error',
      title: '<b>ERROR</b>',
      html: html,
      confirmButtonText: 'Aceptar',
    });
  }

  public showErrorLey1780(message: string, titulo: string, typeIcon: string, title: string = '') {
    Swal.fire({
      type: typeIcon,
      title: '<b>'+ title +'</b>',
      html: '<b>'+ titulo +'</b>' +
        '<p style="margin: 0px;font-size: 16px;">' + message + '</p>',
      confirmButtonText: 'Aceptar',
    });
  }

  public expiredToken () {
    this.showError('Su sesión ha expirado. Por favor inicie sesión nuevamente');
  }

  public showSuccess(message: string) {
    Swal.fire({
      type: 'success',
      title: '<b>CORRECTO</b>',
      html: '<p style="margin: 0px;font-size: 16px;">' + message + '</p>',
      confirmButtonText: 'Aceptar',
    });
  }

  public showSuccessNotify(message: string) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 3000
    });
    Toast.fire({
      type: 'success',
      title: message
    });
  }

  public showWarning(message: string) {
    Swal.fire({
      type: 'warning',
      title: '<b>ADVERTENCIA</b>',
      html: '<p style="margin: 0px;font-size: 16px;">' + message + '</p>',
      confirmButtonText: 'Aceptar',
    });
  }

  public mensajePromesa(text: any) {
    const promise: Promise<boolean> = new Promise((resolve, reject) => {
      Swal.fire({
        type: 'warning',
        title: '<b>ADVERTENCIA</b>',
        html: '<p style="margin: 0px;font-size: 16px;">' + text + '</p>',
        confirmButtonText: 'Aceptar',
      }).then(result => {
        result.value ? resolve(true) : reject(false);
      });
    })
    return promise;
  }

  public showInfo(message: string) {
    Swal.fire({
      type: 'info',
      html: '<p style="margin: 0px;font-size: 16px;">' + message + '</p>',
      confirmButtonText: 'Aceptar',
    });
  }

  public showInfoLey(message: string) {

    const promise: Promise<boolean> = new Promise((resolve, reject) => {
      Swal.fire({
        type: 'info',
        title: '<b>Verificación del beneficio ley 1780 del 2016</b>',
        html: '<p style="margin: 0px;font-size: 16px;">' + message + '</p>',
        confirmButtonText: 'Continuar',
      }).then(result => {
        result.value ? resolve(true) : reject(false);
      });
    });
    return promise;
  }

  public infoBeneficiario(message: string) {

    const promise: Promise<boolean> = new Promise((resolve) => {
      Swal.fire({
        type: 'info',
        title: '<b>No es beneficiario</b>',
        html: '<p style="margin: 0px;font-size: 16px;">' + message + '</p>',
        confirmButtonText: 'Aceptar',
      }).then(() => resolve(true));
    });
    return promise;
  }

  public showWarningNotify(message: string) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 3000
    });
    Toast.fire({
      type: 'warning',
      title: message
    });
  }

  public manageFormError(request: any, message?: string) {
    if (message == null || message === '') {
      message = 'Se ha presentado el(los) siguiente(s) error(es):';
    }
    Swal.fire({
      type: 'error',
      title: '<b>ERROR</b>',
      html: `<b>${message}</b>` +
        this.createErrorsTable(request),
      confirmButtonText: 'Aceptar',
    });
  }

  public manageFormInfo(request: any, message?: string) {
    Swal.fire({
      type: 'info',
      title: '<b>ESTADO</b>',
      html: this.createErrorsTable(request),
      confirmButtonText: 'Aceptar',
    });
  }

  public manageInfoCompensacion(text: any) {
    const promise: Promise<boolean> = new Promise((resolve, reject) => {
      Swal.fire({
        type: 'info',
        title: '<b>IMPORTANTE</b>',
        html: `${text}`,
        confirmButtonText: 'Aceptar',
      }).then(result => {
        result.value ? resolve(true) : reject(false);
      });
    })
    return promise;
  }

  public confirm(title: string, text: string, typeIcon: string, btnAccept?: string, btnNega?: string, showCancelButton:boolean = true) {

    if (btnAccept == null || btnAccept === '') btnAccept = 'Aceptar';
    if (btnNega == null || btnNega === '') btnNega = 'Cancelar';

    const promise: Promise<boolean> = new Promise((resolve, reject) => {
      Swal.fire({
        title: title,
        html: `${text}`,
        type: typeIcon,
        showCancelButton: showCancelButton,
        reverseButtons: true,
        confirmButtonText: btnAccept,
        cancelButtonText: btnNega,
        allowOutsideClick: false
      }).then(result => {
        result.value ? resolve(true) : reject(false);
      });
    });
    return promise;
  }

  public confirmRechazoLey1780(title: string, subTitulo: string, text: string, typeIcon: string, btnAccept?: string, btnNega?: string, showCancelButton:boolean = true) {

    if (btnAccept == null || btnAccept === '') btnAccept = 'Aceptar';
    if (btnNega == null || btnNega === '') btnNega = 'Cancelar';

    const promise: Promise<boolean> = new Promise((resolve, reject) => {
      Swal.fire({
        title: title,
        html: '<b>'+ subTitulo +'</b>' + '<p>'+text+'</p>',
        type: typeIcon,
        showCancelButton: showCancelButton,
        reverseButtons: true,
        confirmButtonText: btnAccept,
        cancelButtonText: btnNega,
        allowOutsideClick: false
      }).then(result => {
        result.value ? resolve(true) : reject(false);
      });
    });
    return promise;
  }

  public createErrorsTable(errors: any) {
    let result = '<ul style="list-style: none;">';
    for (let i = 0; i < errors.length; i++) {
      result += '<li style="margin: 5px;margin-bottom: 20px;font-size: 17px;text-align: center;">' + errors[i] + '</li>';
    }
    result += '</ul>';
    return result;
  }
}
