import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";

import { MatCardModule } from '@angular/material/card';
import { CargaProcesoModule } from '../carga-proceso/carga-proceso.module';
// import { ResumenComponent } from '../../../formulario/main/resumen/resumen.component';
import { CircularProgressComponent } from './circularprogress.component';

@NgModule({
    declarations: [CircularProgressComponent, /* ResumenComponent */],
    imports: [MatCardModule, CargaProcesoModule, CommonModule],
    exports: [CircularProgressComponent, /* ResumenComponent */]
})

export class CircularProgressModule { }
