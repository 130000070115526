import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './shared/modules/material.module';
import { AppComponent } from './app.component';
import { InicioComponent } from './inicio/inicio.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserIdleModule } from 'angular-user-idle';
import { HttpClientModule } from '@angular/common/http';

import { GeneralService } from 'src/app/shared/services/general.service';
import { ExpirationTimeService } from './shared/services/expiration-time.service';


@NgModule({
  declarations: [
    AppComponent,
    InicioComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    UserIdleModule.forRoot({idle: 600, timeout: 1})
  ],
  providers: [GeneralService, ExpirationTimeService],
  bootstrap: [AppComponent]
})

export class AppModule { }
