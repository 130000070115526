import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InicioComponent } from './inicio/inicio.component';
import { PreloadService } from './shared/services/preload.service';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'inicio', component: InicioComponent },
  {
    path: 'login',
    loadChildren: () => import('./login/loginpage.module').then(m => m.LoginPageModule),
    data: { preload: true }
  },
  {
    path: 'formulario',
    loadChildren: () => import('./formulario/formulario.module').then(m => m.FormularioModule),
    data: { preload: true }
  },
  {
    path: 'firma',
    loadChildren: () => import('./firma/firma.module').then(m => m.FirmaModule),
    data: { preload: true }
  },
  {
    path: 'pago',
    loadChildren: () => import('./pago/pago.module').then(m => m.PagoModule),
    data: { preload: true }
  },
  {
    path: 'descargue',
    loadChildren: () => import('./descargue/descargue.module').then(m => m.DescargueModule),
    data: { preload: true }
  },
  {
    path: 'consulta-ordenes',
    loadChildren: () => import('./consulta-ordenes/consulta-ordenes.module').then(m => m.ConsultaOrdenesModule),
    data: { preload: true }
  },
  { path: '**', redirectTo: 'login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadService
  })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
