import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { CargaProcesoComponent } from './carga-proceso.component';

@NgModule({
    declarations: [CargaProcesoComponent],
    imports: [MatCardModule, MatProgressBarModule, CommonModule],
    exports: [CargaProcesoComponent]
})

export class CargaProcesoModule { }
