import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';

import {
  environment,
  getTerminos,
  getTipoIdentificacion,
  getPaises,
  getMunicipios,
  getZonas,
  getUbicacciones,
  getDirecciones,
  getDepartamentos,
  getSedesAdministrativas,
  getGruposNIIF,
  getTipoAportante,
  getTipoExportadorImportador,
  getEntesControl,
  getTiposReferencias,
  formOrganizacion,
  getCiiu,
  getTipoIdentificacionLogin,
  putDirecciones,
  getNotificaciones,
  allLey1780,
  getLoginFormulario,
  getTexto,
  getMatriculas,
  pago
} from 'src/environments/environment';
import { HttpProxy } from 'src/app/shared/services/http.proxy.class';
import { Direccion } from 'src/app/formulario/main/matriculas/matricula.interface';
import { Notificacion } from '../interfaces/notificacion.interface';
import { Personal } from '../components/ley1780/personal-ocupado/personal-ocupado.component';
import { TokenTime } from 'src/app/login/auth/auth.interface';

@Injectable()

export class GeneralService {

  private proxy: HttpProxy;

  constructor(private http: HttpClient,) {
    const serviceUri = environment.host;
    this.proxy = new HttpProxy(http, serviceUri);
  }

  /** @description Metodo que permite Obtener la ip */
  public getIp<T>() {
    return this.http.get<T>('https://api.ipify.org/?format=json').pipe(retry(1)).toPromise();
  }

  public terminosCondiciones<T>(): Observable<T> {
    return this.proxy.get<T>(getTerminos)
      .pipe(retry(1));
  }

  public tiposIdentificacion<T>(): Observable<T> {
    return this.proxy.get<T>(getTipoIdentificacion)
      .pipe(retry(1));
  }

  public tiposIdentificacionLogin<T>(): Observable<T> {
    return this.proxy.get<T>(getTipoIdentificacionLogin)
      .pipe(retry(1));
  }

  public getSedesAdministrativas<T>(): Observable<T> {
    return this.proxy.get<T>(getSedesAdministrativas)
      .pipe(retry(1));
  }

  public getPaises<T>(): Observable<T> {
    return this.proxy.get<T>(getPaises)
      .pipe(retry(1));
  }

  public getDepartamentos<T>(idPais: string): Observable<T> {
    return this.proxy.get<T>(getDepartamentos, `?idPais=${idPais}`)
      .pipe(retry(1));
  }

  public getMunicipios<T>(IdDepartamento: string): Observable<T> {
    return this.proxy.get<T>(getMunicipios, `?IdDepartamento=${IdDepartamento}`)
      .pipe(retry(1));
  }

  public getMunicipiosCCB<T>(): Observable<T> {
    return this.proxy.get<T>(getMunicipios, '/CCB')
      .pipe(retry(1));
  }

  public getZonas<T>(): Observable<T> {
    return this.proxy.get<T>(getZonas)
      .pipe(retry(1));
  }

  public getUbicaciones<T>(): Observable<T> {
    return this.proxy.get<T>(getUbicacciones)
      .pipe(retry(1));
  }

  public getDirecciones<T>(idFormulario: string) {
    return this.proxy.get<T>(getDirecciones, `${idFormulario}/direcciones`)
      .pipe(retry(1)).toPromise();
  }

  public getGruposNIIF<T>() {
    return this.proxy.get<T>(getGruposNIIF)
      .pipe(retry(1));
  }

  public getTipoAportante<T>(): Observable<T> {
    return this.proxy.get<T>(getTipoAportante).pipe(retry(1));
  }

  public getTipoExportadorImportador<T>(): Observable<T> {
    return this.proxy.get<T>(getTipoExportadorImportador).pipe(retry(1));
  }

  public getEntidadesInspeccion<T>() {
    return this.proxy.get<T>(getEntesControl).pipe(retry(1));
  }

  public putDirecciones<T>(idFormulario: string, data: Direccion) {
    const services = `${putDirecciones}/${idFormulario}`;
    return this.proxy.put<T>(services, data)
      .pipe(retry(1)).toPromise();
  }

  public putDireccionesEstablecimientos<T>(idFormulario: string, data: Direccion) {
    const services = `${getDirecciones}${idFormulario}/direccionesestablecimientos`;
    return this.proxy.put<T>(services, data)
      .pipe(retry(1)).toPromise();
  }

  public getTiposReferencias<T>() {
    return this.proxy.get<T>(getTiposReferencias).pipe(retry(1));
  }

  public getEsEsal<T>(idFormulario) {
    const service = `${formOrganizacion}/${idFormulario}/esEsal/`;
    return this.proxy.get<boolean>(service).pipe(retry(1));
  }
  public getSearchCIIU<T>(data: string, type: string) {
    const service = type == 'codigo' ? `${getCiiu}/${data}` : `${getCiiu}?descripcion=${data}`;
    return this.proxy.get<T>(service).pipe(retry(1)).toPromise();

  }
  public getSearchSHD<T>(code: string) {
    const service = `${getCiiu}_SHD/${code}`;
    return this.proxy.get<T>(service).pipe(retry(1)).toPromise();
  }

  public getNotificaciones<T>(numeroCuf: string) {
    const service = `${getNotificaciones}/${numeroCuf}`;
    return this.proxy.get<T>(service).pipe(retry(1)).toPromise();
  }

  public postCambiarEstadoNotificacion<T>(numeroCuf: string, notificacion: Notificacion) {
    const service = `${getNotificaciones}/${numeroCuf}`;
    return this.proxy.post<T>(service, notificacion).toPromise();
  }

  public getEsBeneficiario<T>(idFormulario: number, numMatricula: string ) {
    const service = `${allLey1780}/validaBeneficioLey1780/${idFormulario}/${numMatricula}`;
    return this.proxy.get<T>(service).pipe(retry(1)).toPromise();
  }

  public cargarDatosLey1780<T>(idFormulario: number, numMatricula: string ) {
    const service = `${allLey1780}/cargar/${idFormulario}/${numMatricula}`;
    return this.proxy.get<T>(service).pipe(retry(1)).toPromise();
  }

  getEsBeneficiarioEdad<T>(idFormulario: number, numMatricula: string ) {
    const service = `${allLey1780}/validaEdad/${numMatricula}/${idFormulario}`;
    return this.proxy.get<T>(service).pipe(retry(1)).toPromise();
  }

  public getCantEmpleados<T>(idFormulario: number, cantEmpleados: number ) {
    const service = `${allLey1780}/validarCantidadMaxEmpledos/${idFormulario}/${cantEmpleados}`;
    return this.proxy.get<T>(service).pipe(retry(1)).toPromise();
  }

  
  // public eliminarEmpleados<T>(idFormulario: number, dataEmpleado: any ) {
  //   const service = `${allLey1780}/formularios/${idFormulario}/empleado`;
  //   return this.proxy.deleteBody<T>(service, dataEmpleado).pipe(retry(1)).toPromise();
  // }

  public eliminarEmpleados<T>(idFormulario: number, dataEmpleado: Personal ) {
    const service = `${allLey1780}/formularios/${idFormulario}/${dataEmpleado.idTipo}/${dataEmpleado.numId}/empleado`;
    return this.proxy.delete<T>(service).pipe(retry(1)).toPromise();
  }

  public agregarEmpleados<T>(idFormulario: number, dataEmpleado: any ) {
    const service = `${allLey1780}/empleado/${idFormulario}`;
    return this.proxy.post<T>(service, dataEmpleado).pipe(retry(1)).toPromise();
  }

  public agregarEstadoFinanciero<T>(idFormulario: number, dataArchivo: FormData ) {
    const service = `${allLey1780}/archivo/estados-financieros?idFormulario=${idFormulario}`;
    return this.proxy.postCargarArchivo<T>(service, dataArchivo).pipe(retry(1)).toPromise();
  }

  public eliminarEstadoFinanciero<T>(idFormulario: number) {
    const service = `${allLey1780}/archivo/eliminar-ef?idFormulario=${idFormulario}`;
    return this.proxy.post<T>(service, null).pipe(retry(1)).toPromise();
  }

  public getEmpleados<T>(idFormulario: number ) {
    const service = `${allLey1780}/empleados/${idFormulario}`;
    return this.proxy.get<T>(service).pipe(retry(1)).toPromise();
  }
  
  public finalizarLey1780<T>(idFormulario: number ) {
    const service = `${allLey1780}/finalizaBeneficioLey1780/${idFormulario}`;
    return this.proxy.get<T>(service).pipe(retry(1)).toPromise();
  }

  public rechazoLey1780<T>(idFormulario: number ) {
    const service = `${allLey1780}/rechazoBeneficioLey1780/${idFormulario}`;
    return this.proxy.get<T>(service).pipe(retry(1)).toPromise();
  }

  public postCumpleRequisitos<T>(data: any, idFormulario) {
    const service = `${allLey1780}/${idFormulario}/paso1`;
    return this.proxy.post<T>(service, data).toPromise();
  }

  public getDatosLey1780<T>(idFormulario, numMatricula) {
    const service = `${allLey1780}/cargar/${idFormulario}/${numMatricula}`;
    return this.proxy.get<T>(service).toPromise();
  }

  public getActivoTotal<T>(idFormulario: number, activoTotal: number ) {
    const service = `${allLey1780}/validarActivosPersonaLimitMax/${idFormulario}/${activoTotal}`;
    return this.proxy.get<T>(service).pipe(retry(1)).toPromise();
  }

  public getAllSocios<T>(idFormulario: number, numMatricula: number ) {
    const service = `${allLey1780}/validaBeneficioLey1780/juridica/${idFormulario}/${numMatricula}`;
    return this.proxy.get<T>(service).pipe(retry(1)).toPromise();
  }

  public postAgregarSocio<T>(data: any) {
    const service = `${allLey1780}/accionista/${data.idFormulario}`;
    return this.proxy.post<T>(service, data).toPromise();
  }

  public eliminarSocio<T>(data: any) {
    const service = `${allLey1780}/accionista/${data.idFormulario}/${data.idTipo}/${data.numId}`;
    return this.proxy.delete<T>(service).toPromise();
  }

  public agregarArchivoSocio<T>(idFormulario: number, dataArchivo: FormData, numId: string, item: any ) {
    const valor= item==null?'=':`=${item} `
    const service = `${allLey1780}/archivo/accionista?idFormulario=${idFormulario}&numId=${numId}&item${valor}`;
    return this.proxy.postCargarArchivo<T>(service, dataArchivo).pipe(retry(1)).toPromise();
  }

  public eliminarArchivoSocio<T>(idFormulario: number, dataArchivo: FormData, item: any ) {
    const valor= item==null?'=':`=${item} `
    const service = `${allLey1780}/archivo/eliminar-acc?idFormulario=${idFormulario}&item${valor}`;
    return this.proxy.post<T>(service, dataArchivo).pipe(retry(1)).toPromise();
  }

  public validarTotalAcciones<T>(idFormulario: number) {
    const service = `${allLey1780}/accionista/validarTotalAcciones/${idFormulario}`;
    return this.proxy.get<T>(service).toPromise();
  }

  public getValidarEdadSocios<T>(idFormulario: number) {
    const service = `${allLey1780}/accionista/${idFormulario}/validarRangoEdades`;
    return this.proxy.get<T>(service).toPromise();
  }

  public dowloadFile<T>(idformulario: number) {
    const service = `${allLey1780}/archivo/descarga-ef/${idformulario}`;
    return this.proxy.binaryDataPDF(service);
  }

  public dowloadFileSocio<T>(idformulario: number, item) {
    const service = `${allLey1780}/archivo/descarga-acc/${idformulario}/${item}`;
    return this.proxy.binaryDataPDF(service);
  }

  public tokenTime<T>() {
    const service = `${getLoginFormulario}/token`;
    return this.proxy.get<T>(service).toPromise();
  }

  public validarPagoCaja<T>(data: any) {
    const service = `${pago}/validar-otros-medios/`;
    return this.proxy.post<T>(service, data).toPromise();
  }
  
  public getTexto<T>(idTexto: number) {
    const service = `${getTexto}/${idTexto}`;
    return this.proxy.get<T>(service).toPromise();
  }

  public getDatosCamara<T>(idFormulario: number) {
    const service = `${getMatriculas}/camaraByFormulario/${idFormulario}`;
    return this.proxy.get<T>(service).toPromise();
  }
}
